import React, { useState, useEffect } from 'react';

// External Components
import { Container, Row, Col, Form, Card } from 'react-bootstrap';
import { Dashboard } from '@uppy/react';

// Internal Components
import CastedModal from 'components/shared/modal';
import CastedToggleButtonGroup from 'components/shared/toggleButtonGroup';
import CastedButton from 'components/shared/button';
import Checkbox from 'components/shared/checkbox';
import YoutubeForm from 'components/shared/youtubeForm';

// Internal constants
import {
	EPISODE_UPLOAD_TYPES,
	CONTENT_TYPE,
	UPLOAD_WIZARD_STEPS,
} from 'components/constants';

import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';

const UploadWizard = (props) => {
	const {
		showUploadWizard,
		podcastIsHosted,
		episodePermission,
		episodeId,
		podcast,
		accountId,
		enableVideo,
		storageLink,
		rssAudioStorageLink,
		uploadType = 'audio',
		onSelect = () => {},
		onUploadStart = () => {},
		onClose = () => {},
		episodeContentType,
		uploadToYoutube,
		setUploadToYoutube,
		wizardStep,
		description,
		name,
		youtubeTitle,
		showThumbnail,
		thumbnail,
		filesToUpload,
		youtubeIntegration,
		uppyEpisode,
		uppyRss,
	} = props;

	const toggleButtonList = [
		{
			text: 'Yes',
			value: true,
		},
		{
			text: 'No',
			value: false,
		},
	];

	const [showRssUpload, setShowRssUpload] = useState(false);

	const onToggleChange = (newValue) => {
		setShowRssUpload(!newValue);
	};

	return (
		<CastedModal
			className="upload-wizard-modal"
			wideModal
			show={showUploadWizard}
			handleClose={onClose}
			backdrop={'static'}
		>
			<Container
				className={`upload-wizard-container ${
					wizardStep === UPLOAD_WIZARD_STEPS.publish_to_youtube &&
					'youtube-setup'
				}`}
			>
				{wizardStep === UPLOAD_WIZARD_STEPS.upload_content && (
					<Row>
						<Col className="p-4 border-right">
							{!storageLink && (
								<h4 className="mb-3 text-center">
									Upload{' '}
									{`${
										uploadType === EPISODE_UPLOAD_TYPES.VIDEO
											? 'Video'
											: 'Audio'
									}`}{' '}
									File
								</h4>
							)}
							<Container className="p-0">
								{!storageLink && (
									<Row noGutters>
										<Col>
											<div className="mb-4">
												<Card className="episode-uploader">
													<Dashboard
														uppy={uppyEpisode}
														// plugins={['GoogleDrive']}
														disabled={
															!podcastIsHosted || !episodePermission.canEdit
														}
														inline={true}
														height={250}
														hideCancelButton={false}
														hideUploadButton={true}
														hideRetryButton={true}
														proudlyDisplayPoweredByUppy={false}
														disableStatusBar={true}
														disableThumbnailGenerator={true}
														locale={{ strings: { cancel: 'Remove' } }}
													/>
												</Card>
											</div>
										</Col>
									</Row>
								)}
								{youtubeIntegration &&
									youtubeIntegration.status === 1 &&
									uploadType === EPISODE_UPLOAD_TYPES.VIDEO &&
									!storageLink &&
									!podcast.autoPublishYoutube && 
									false && 
									(
										<div className="youtube-checkbox">
											<Checkbox
												id="check-youtube"
												checked={uploadToYoutube}
												onChange={(e) => setUploadToYoutube(e.target.checked)}
												disabled={filesToUpload.length === 0}
											/>
											<p>Also upload this video to YouTube</p>
										</div>
									)}
								{episodeContentType === CONTENT_TYPE.episode &&
									uploadType === EPISODE_UPLOAD_TYPES.VIDEO && (
										<Row noGutters className="mb-4">
											<Col>
												<h5>RSS Audio</h5>
												<p>
													Do you want Casted to create your podcast audio file
													from your video? This is the file we'll send to RSS
													feeds.
												</p>
												<CastedToggleButtonGroup
													toggleButtonList={toggleButtonList}
													defaultValue={true}
													onChange={onToggleChange}
												/>
											</Col>
										</Row>
									)}
								{episodeContentType === CONTENT_TYPE.episode &&
									uploadType === EPISODE_UPLOAD_TYPES.VIDEO &&
									showRssUpload && (
										<div className="mb-4">
											<Card className="rss-audio-uploader">
												<Dashboard
													uppy={uppyRss}
													// plugins={['GoogleDrive']}
													disabled={!episodePermission.canEdit}
													inline={true}
													height={250}
													hideCancelButton={false}
													hideUploadButton={true}
													hideRetryButton={true}
													proudlyDisplayPoweredByUppy={false}
													disableStatusBar={true}
													disableThumbnailGenerator={true}
													locale={{ strings: { cancel: 'Remove' } }}
												/>
											</Card>
										</div>
									)}
								<Row className="justify-content-end pt-3">
									<Col md="auto">
										<CastedButton onClick={onUploadStart}>
											Start Upload
										</CastedButton>
									</Col>
									<Col md="auto">
										<CastedButton onClick={onClose} variant="outline-dark">
											Cancel
										</CastedButton>
									</Col>
								</Row>
							</Container>
						</Col>
					</Row>
				)}
				{wizardStep === UPLOAD_WIZARD_STEPS.publish_to_youtube &&
					uploadToYoutube && (
						<YoutubeForm
							accountId={accountId}
							episodeId={episodeId}
							description={description}
							title={youtubeTitle}
							showThumbnail={showThumbnail}
							thumbnail={thumbnail}
							uploadVideo={onUploadStart}
						/>
					)}
			</Container>
		</CastedModal>
	);
};

export default UploadWizard;
