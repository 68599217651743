import { gql } from '@apollo/client';

export default {
	AccountParts: gql`
		fragment AccountParts on Account {
			id
			name
			domain
			enableVideo
			createdOn
			__typename
		}
	`,
	PodcastParts: gql`
		fragment PodcastParts on Podcast {
			id
			accountId
			name
			slug
			description
			author
			status
			thumbnail
			tags
			brandSettings
			syndicationLinks
			customHtmlTags
			featuredEpisode
			feedUrl
			webUrl
			createdAt
			updatedAt
			externalId
			customDomain
			rssDomain
			rssAuthor
			rssEmail
			rssCopyright
			rssCategory
			customNavItems
			rssLanguageId
			rssCategoryId1
			rssCategoryId2
			rssCategoryId3
			alternateRssLink
			trackingPrefix
			explicit
			disablePremiumTranscript
			disableLandingPages
			collectionType
			collectionLevelPermissions
			episodes {
				id
				name
				thumbnail
				uploadType
			}
			autoPublishYoutube
			youtubeChannelId
			youtubePlaylistId
			collectionPermissions {
				id
				userId
				roleId
			}
			salesforceCampaignId

			__typename
		}
	`,
	EpisodeParts: gql`
		fragment EpisodeParts on Episode {
			id
			podcastId
			name
			description
			showNotes
			status
			publicStatus
			slug
			thumbnail
			keywords
			storageLink
			waveformLink
			hostedUrl
			createdAt
			updatedAt
			duration
			season
			episode
			externalId
			humanTransOrderNumber
			showTranscript
			socialThumbnail
			metaDescription
			guests
			audioLink
			videoLink
			rssAudioStorageLink
			alternateRssLink
			explicit
			uploadType
			type
			publishedAt
			seoTitle
			socialCardDescription
			youtubeTitle
			youtubeId
			youtubeDescription
			youtubeThumbnail
			__typename
		}
	`,
	ClipParts: gql`
		fragment ClipParts on Clip {
			id
			name
			description
			internalDescription
			status
			storageLink
			slug
			thumbnail
			episodeId
			keywords
			startTime
			endTime
			applyPromo
			standaloneStorageLink
			source
			createdAt
			updatedAt
			type
			suggestionReason
			__typename
		}
	`,
	JobParts: gql`
		fragment JobParts on Job {
			id
			accountId
			status
			type
			objectId
			metadata
			createdAt
			updatedAt
			progress
			__typename
		}
	`,
	ResourceParts: gql`
		fragment ResourceParts on Resource {
			id
			episodeId
			name
			url
			thumbnail
			order
			__typename
		}
	`,
	GraphileJobParts: gql`
		fragment GraphileJobParts on GraphileJob {
			id
			queueName
			taskIdentifier
			payload
			priority
			runAt
			attempts
			maxAttempts
			lastError
			createdAt
			updatedAt
		}
	`,
	ShowCategoryParts: gql`
		fragment ShowCategoryParts on ShowCategory {
			id
			category_id
			name
			permalink
			level
		}
	`,
	LanguageParts: gql`
		fragment LanguageParts on Language {
			id
			name
			code
		}
	`,
	AudiogramTemplateParts: gql`
		fragment AudiogramTemplateParts on AudiogramTemplate {
			id
			name
			thumbnail
			templateMediaType
		}
	`,
	IntegrationParts: gql`
		fragment IntegrationParts on Integration {
			id
			podcastId
			integrationName
			status
			integrationSettings {
				id
				integrationId
				settingName
				settingValue
				isSystem
				createdAt
				updatedAt
			}
			createdAt
			updatedAt
		}
	`,
	AccountIntegrationParts: gql`
	fragment AccountIntegrationParts on AccountIntegrations {
		id
		accountId
		integrationName
		status
		accountIntegrationSettings {
			id
			integrationId
			settingName
			settingValue
			createdAt
			updatedAt
		}
		createdAt
		updatedAt
	}
`,
	PermissionObjectParts: gql`
		fragment PermissionObjectParts on PermissionObject {
			id
			name
			slug
			status
			rolePermission {
				id
				roleId
				permissionObjectId
				canView
				canEdit
			}
		}
	`,
	UserParts: gql`
		fragment UserParts on User {
			id
			firstName
			lastName
			email
			createdOn
			updatedOn
			confirmed
			avatarUrl
			roles {
				id
				name
				description
				status
				slug
			}
			accountUsers {
				id
				accountId
				userId
				inviteCode
				invitedOn
				status
			}
			collectionPermissions {
				id
				userId
				roleId
			}
		}
	`,
	UserRoleParts: gql`
		fragment UserRoleParts on UserRole {
			id
			name
			slug
			description
			status
		}
	`,
	ApiKeyParts: gql`
		fragment ApiKeyParts on ApiKey {
			id
			name
			clientId
			status
			createdAt
			updatedAt
			integrationName
			isAccountLevel
		}
	`,
	SamlParts: gql`
		fragment SamlParts on AccountSaml {
			id
			issuer
			ssoUrl
			cert
			signatureAlgorithm
			status
			createdAt
			updatedAt
			forceSso
		}
	`,
	ThemeParts: gql`
		fragment ThemeParts on Theme {
			id
			accountId
			name
			keywords
			status
			color
			createdAt
			updatedAt
			publicStatus
			matchThreshold
		}
	`,
	ExternalObjectParts: gql`
		fragment ExternalObjectParts on ExternalObject {
			id
			accountId
			status
			name
			url
			objectTypeId
			extra
			parentId
			externalId
			externalCreatedAt
			externalUpdatedAt
			createdAt
			updatedAt
			externalObjectType {
				id
				objectName
				objectImage
				objectNamePlural
			}
		}
	`,
	ExternalObjectTypeParts: gql`
		fragment ExternalObjectTypeParts on ExternalObjectType {
			id
			objectName
			objectImage
			objectNamePlural
			externalObjects {
				id
				accountId
				status
				name
				url
				objectTypeId
				extra
				parentId
				externalId
				externalCreatedAt
				externalUpdatedAt
				createdAt
				updatedAt
			}
		}
	`,
	CollectionPermissionParts: gql`
		fragment CollectionPermissionParts on CollectionPermission {
			__typename
			id
			accountId
			userId
			roleId
			collection {
				id
				name
			}
		}
	`,
	PlaylistParts: gql`
		fragment PlaylistParts on Playlist {
			id
			accountId
			name
			status
			slug
			color
			content
			createdBy
			createdAt
			updatedAt
			user {
				firstName
				lastName
			}
			customDomain
		}
	`,
	SegmentParts: gql`
		fragment SegmentParts on Segment {
			id
			accountId
			clearbitProperty
			category
			createdAt
			updatedAt
			segmentGroups {
				id
				segmentId
				name
				operator
				stringValues
				numberValues
			}
		}
	`,
	ShareParts: gql`
		fragment ShareParts on Share {
			id
			accountId
			createdBy
			updatedBy
			description
			destination
			shareSlug
			playlistId
			collectionId
			itemId
			clipId
			shareType
			contentType
			shareOption
			status
			title
			createdUser {
				firstName
				lastName
			}
			createdAt
			updatedAt
		}
	`,
	PromotionParts: gql`
		fragment PromotionParts on Promotion {
			id
			accountId
			title
			description
			position
			startDate
			endDate
			rangeStart
			rangeEnd
			collectionId
			itemId
			status
			createdBy
			updatedBy
			createdAt
			updatedAt
			url
			thumbnail
		}
	`,
};
